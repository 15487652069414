.mic-report table{
    border-spacing: none;
    border: 1px solid black;
}

.mic-report caption{
    padding: 8px;
    border: 1px solid black;
    border-bottom: none;
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}

.mic-report tr:nth-child(even){
    background-color: #f2f1f1;
}

.mic-report tr:nth-child(odd):hover{
    background-color: #f2f1f1;
}

.mic-report tr:nth-child(even):hover{
    background-color: #ffffff;
}

.mic-report th, .mic-report td{
    margin: none;
    padding: 0.5rem;
    border: 1px solid black;
}

.mic-report strong{
    font-family: 'SFProDisplaySemibold', Arial, sans-serif;
}
